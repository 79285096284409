
import { defineComponent, computed, ref, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import WideBlock from '@/components/atom/WideBlock.vue'
import authApi from '@/api/Authenticated'
import CustomButton from '@/components/atom/CustomButton.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import Tooltip from '@/components/atom/Tooltip.vue'
import { studioTypes } from '@shared/*'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    DashboardSection,
    OverviewBlock,
    WideBlock,
    CustomButton,
    Tooltip
  },
  setup() {
    const studioDashboard = ref<studioTypes.StudioDashboardResponse>({
      StudioDashboardCalculations: []
    })
    onMounted(async () => {
      studioDashboard.value = await authApi.getStudioDashboardDetail()
    })

    const roundValue = (percentage: number) => Math.round(percentage * 100)
    const overViewBlockData = computed(() => {
      return [
        {
          id: 1,
          blockHeading: 'Control',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlScore)
              : '-',
          tooltipText:
            'How much are you in control of the basics? The degree to which all your documents are in place, your invoices are sent out on time and cash is received. 100% = fully in control. Less than 100% means something is missing. Your task bar will tell you what to do.',
          fields: [
            {
              id: 11,
              name: 'Studio documents',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlStudioDocuments)
                  : '-',
              subText: '-',
              link: 'Documents'
            },
            {
              id: 12,
              name: 'Project control',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlProjectDocuments)
                  : '-',
              subText: '-',
              link: 'ProjectListing'
            },
            {
              id: 13,
              name: 'Invoices',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlInvoices)
                  : '-',
              subText: '-',
              link: 'ProjectListing'
            },
            {
              id: 14,
              name: 'Cash flow',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlCashFlow)
                  : '-',
              subText: '-',
              link: 'BooksDashboard'
            }
          ]
        },
        {
          id: 2,
          blockHeading: 'Performance',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].performanceProfitRecovery)
              : '-',
          tooltipText:
            'How well is your studio performing in terms of revenue generation, and profitability? Key is profit recovery, your budgeted or expected profit v actual. 100% means all profit is recovered. Less that 100% means you are putting more time into your projects that you are budgeting for.',
          fields: [
            {
              id: 21,
              name: 'Total revenue',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].performanceTotalValue)
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 22,
              name: 'Average profit recovery',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].performanceProfitRecovery)
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 23,
              name: 'Profit per head',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].performanceDailyProfitPerHead)
                  : '-',
              subText: '',
              link: null
            }
          ]
        },
        {
          id: 3,
          blockHeading: 'Growth',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthSalesForecast)
              : '-',
          tooltipText:
            'How confident you are about your future? Can you confidently see future sales and is your capacity matched accordingly?',
          fields: [
            {
              id: 31,
              name: 'Sales forecast',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthSalesForecast)
                  : '-',
              link: null,
              subText: 'next 12 months'
            },
            {
              id: 32,
              name: 'Cash forecast',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthCashForecast)
                  : '-',
              link: null,
              subText: 'next 12 months'
            },
            {
              id: 33,
              name: 'Capacity growth',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? studioDashboard.value.StudioDashboardCalculations[0].growthTeam
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 34,
              name: 'Improve your growth',
              percentage: 0,
              subText: 'Coming soon',
              link: null
            }
          ]
        }
      ]
    })
    const wideBlockData = computed(() => {
      return [
        {
          id: 4,
          blockHeading: 'Your tasks',
          content: [
            {
              id: 41,
              taskName: 'Produce MSA',
              projectName: 'PJ Sullivan'
            },
            {
              id: 42,
              taskName: 'Produce insurance',
              projectName: 'PJ Sullivan'
            },
            {
              id: 43,
              taskName: 'Produce invoicing terms',
              projectName: 'PJ Sullivan'
            }
          ]
        },
        {
          id: 5,
          blockHeading: 'Insights',
          content: [
            {
              id: 51,
              taskName: 'Invoice cleared',
              projectName: 'PJ Sullivan'
            },
            {
              id: 52,
              taskName: 'SOW created',
              projectName: 'PJ Sullivan'
            },
            {
              id: 53,
              taskName: 'Invoice terms signed',
              projectName: 'PJ Sullivan'
            }
          ]
        }
      ]
    })
    return { overViewBlockData, wideBlockData }
  }
})
