<template>
  <DashboardSection>
    <template #content>
      <div class="block-wrapper">
        <OverviewBlock v-for="item in overViewBlockData" :key="item.id">
          <template #heading> {{ item.blockHeading }} </template>
          <template #questionIcon>
            <Tooltip
              :tooltipTitle="item.blockHeading"
              :tooltipText="item.tooltipText"
              :fieldType="item.blockHeading === 'Growth' ? 'growth' : 'dashboard'"
              class="inline-flex ml-auto"
            >
              <img src="@/assets/icons/question-mark.svg" />
            </Tooltip>
          </template>
          <template #status v-if="item.blockHeading != 'Growth'">
            <span class="block__status green-dot" v-if="item.score > 90"></span>
            <span class="block__status mustard-dot" v-if="item.score >= 70 && item.score <= 90"></span>
            <span class="block__status red-dot" v-if="item.score < 70"></span>
          </template>
          <template #score> {{ item.blockHeading != 'Growth' ? item.score + '%' : '' + item.score }} </template>
          <template #performance v-if="item.blockHeading !== 'Growth'">
            <p class="avg"><span class="arrow-up"></span> -</p>
            <!-- <span>30 day rolling avg.</span> -->
          </template>
          <template #blockFields>
            <div v-for="fields in item.fields" :key="fields.id">
              <div :class="fields.name === 'Improve your growth' ? 'block__row darkGrey' : 'block__row'">
                <router-link v-if="fields.link !== null" :to="{ name: fields.link }">
                  <p class="block__fieldName">
                    {{ fields.name }}
                    <span v-if="item.blockHeading == 'Growth'" class="subtext">{{ fields.subText }}</span>
                  </p>
                </router-link>
                <p class="block__fieldName" v-else>
                  {{ fields.name }}
                  <span v-if="item.blockHeading == 'Growth'" class="subtext">{{ fields.subText }}</span>
                </p>
                <p class="block__value" v-if="item.blockHeading == 'Control'">
                  {{ fields.percentage }}%
                  <span class="dot green-dot" v-if="fields.percentage == 100"></span>
                  <span class="dot mustard-dot" v-if="fields.percentage >= 75 && fields.percentage <= 99"></span>
                  <span class="dot red-dot" v-if="fields.percentage < 75"></span>
                </p>
                <p class="block__value" v-else-if="item.blockHeading == 'Growth'">
                  <span v-if="fields.name !== 'Improve your growth'"
                    >{{ fields.name != 'Capacity growth' ? '' : '' }}{{ fields.percentage }}</span
                  >
                  <img v-else src="@/assets/icons/growth.svg" class="growth-icon" />
                </p>
                <p class="block__value" v-else>
                  <span class="subtext">{{ fields.subText }}</span>
                  {{ fields.name !== 'Average Profit Recovery' ? '' + fields.percentage : fields.percentage + '%' }}
                  <span class="dot green-dot"></span>
                </p>
              </div>
            </div>
          </template>
        </OverviewBlock>
        <WideBlock v-for="item in wideBlockData" :key="item.id">
          <template #wideBlockContent>
            <div class="block__row">
              <h3 class="block__heading">{{ item.blockHeading }}</h3>
              <span class="block_button">
                <CustomButton :label="'See all'" color="grey" size="small" />
              </span>
            </div>
            <div class="block__row" v-for="content in item.content" :key="content.id">
              <p class="block__value" v-if="item.blockHeading == 'Insights'">
                <!-- <span class="dot purple-dot"></span> <strong>{{ content.taskName }}</strong> for -->
                <strong>Your studio insights will appear here</strong>
                <!-- <strong>{{ content.projectName }}</strong> -->
              </p>
              <!-- <span class="block_datetime" v-if="item.blockHeading == 'Activity'">16:04, 21/04/21 </span> -->
              <p v-else>Your key tasks will appear here</p>
            </div>
          </template>
        </WideBlock>
      </div>
    </template>
  </DashboardSection>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import OverviewBlock from '@/components/atom/OverviewBlock.vue'
import WideBlock from '@/components/atom/WideBlock.vue'
import authApi from '@/api/Authenticated'
import CustomButton from '@/components/atom/CustomButton.vue'
import { intlFormat } from '@/utils/currencyFunctions'
import Tooltip from '@/components/atom/Tooltip.vue'
import { studioTypes } from '@shared/*'
export default defineComponent({
  name: 'ProjectDashboard',
  components: {
    DashboardSection,
    OverviewBlock,
    WideBlock,
    CustomButton,
    Tooltip
  },
  setup() {
    const studioDashboard = ref<studioTypes.StudioDashboardResponse>({
      StudioDashboardCalculations: []
    })
    onMounted(async () => {
      studioDashboard.value = await authApi.getStudioDashboardDetail()
    })

    const roundValue = (percentage: number) => Math.round(percentage * 100)
    const overViewBlockData = computed(() => {
      return [
        {
          id: 1,
          blockHeading: 'Control',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlScore)
              : '-',
          tooltipText:
            'How much are you in control of the basics? The degree to which all your documents are in place, your invoices are sent out on time and cash is received. 100% = fully in control. Less than 100% means something is missing. Your task bar will tell you what to do.',
          fields: [
            {
              id: 11,
              name: 'Studio documents',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlStudioDocuments)
                  : '-',
              subText: '-',
              link: 'Documents'
            },
            {
              id: 12,
              name: 'Project control',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlProjectDocuments)
                  : '-',
              subText: '-',
              link: 'ProjectListing'
            },
            {
              id: 13,
              name: 'Invoices',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlInvoices)
                  : '-',
              subText: '-',
              link: 'ProjectListing'
            },
            {
              id: 14,
              name: 'Cash flow',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].controlCashFlow)
                  : '-',
              subText: '-',
              link: 'BooksDashboard'
            }
          ]
        },
        {
          id: 2,
          blockHeading: 'Performance',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].performanceProfitRecovery)
              : '-',
          tooltipText:
            'How well is your studio performing in terms of revenue generation, and profitability? Key is profit recovery, your budgeted or expected profit v actual. 100% means all profit is recovered. Less that 100% means you are putting more time into your projects that you are budgeting for.',
          fields: [
            {
              id: 21,
              name: 'Total revenue',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].performanceTotalValue)
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 22,
              name: 'Average profit recovery',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? roundValue(studioDashboard.value.StudioDashboardCalculations[0].performanceProfitRecovery)
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 23,
              name: 'Profit per head',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].performanceDailyProfitPerHead)
                  : '-',
              subText: '',
              link: null
            }
          ]
        },
        {
          id: 3,
          blockHeading: 'Growth',
          score:
            studioDashboard.value.StudioDashboardCalculations.length > 0
              ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthSalesForecast)
              : '-',
          tooltipText:
            'How confident you are about your future? Can you confidently see future sales and is your capacity matched accordingly?',
          fields: [
            {
              id: 31,
              name: 'Sales forecast',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthSalesForecast)
                  : '-',
              link: null,
              subText: 'next 12 months'
            },
            {
              id: 32,
              name: 'Cash forecast',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? intlFormat(studioDashboard.value.StudioDashboardCalculations[0].growthCashForecast)
                  : '-',
              link: null,
              subText: 'next 12 months'
            },
            {
              id: 33,
              name: 'Capacity growth',
              percentage:
                studioDashboard.value.StudioDashboardCalculations.length > 0
                  ? studioDashboard.value.StudioDashboardCalculations[0].growthTeam
                  : '-',
              subText: '',
              link: null
            },
            {
              id: 34,
              name: 'Improve your growth',
              percentage: 0,
              subText: 'Coming soon',
              link: null
            }
          ]
        }
      ]
    })
    const wideBlockData = computed(() => {
      return [
        {
          id: 4,
          blockHeading: 'Your tasks',
          content: [
            {
              id: 41,
              taskName: 'Produce MSA',
              projectName: 'PJ Sullivan'
            },
            {
              id: 42,
              taskName: 'Produce insurance',
              projectName: 'PJ Sullivan'
            },
            {
              id: 43,
              taskName: 'Produce invoicing terms',
              projectName: 'PJ Sullivan'
            }
          ]
        },
        {
          id: 5,
          blockHeading: 'Insights',
          content: [
            {
              id: 51,
              taskName: 'Invoice cleared',
              projectName: 'PJ Sullivan'
            },
            {
              id: 52,
              taskName: 'SOW created',
              projectName: 'PJ Sullivan'
            },
            {
              id: 53,
              taskName: 'Invoice terms signed',
              projectName: 'PJ Sullivan'
            }
          ]
        }
      ]
    })
    return { overViewBlockData, wideBlockData }
  }
})
</script>

<style lang="sass" scoped>
.block-wrapper
  width: 100%
  display: grid
  grid-template-columns: repeat(6, 1fr)
  justify-content: space-between
  grid-gap: 1rem
  @media screen and (max-width: $ms-breakpoint)
    grid-template-columns: 1fr
  .subtext
    color: $text-grey
    margin: 0.3rem
.darkGrey
  background: $primary
.darkGrey p
  color: $white
.buttonIcon
  display: flex
  width: 1.5rem
  height: 1.5rem
  background-size: contain
  background-position: center
.growth-icon
  height: 13px
  width: 18px
</style>
